import { useEffect } from 'react';
import './App.css';
import {AutoComplete,Select,Input,Button,Modal,Alert} from 'antd';
import 'antd/dist/antd.css';
import React from 'react';

function App() {

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { Option } = Select;

  const [crypto, setCrypto] = React.useState([]);
  const [value, setValue] = React.useState('');
  const [cross, setCross] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isloaded, setIsloaded] = React.useState(false);
  
  const options = []
  crypto.map(item => {
    options.push({"value":item})
  })

  useEffect(() => {
    fetch('https://45tm54.deta.dev/api/v1/pairs')
      .then(res => res.json())
      .then(data => {
        let pairs = data['pair'];
        setCrypto(pairs);
      });
  }, []);

  const handleSelect = (value) => {
    setValue(value);
  }

  const handleCross = (value) => {
    setCross(value);
  }

  const handlePrice = (e) => {
    setPrice(e.target.value);
  }

  const handleUsername = (e) => {
    setUsername(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(value);
    console.log(cross);
    console.log(price);
    setIsloaded(true);
    setMessage('Setting alert for'+ ' ' + value +'...');
    fetch('https://45tm54.deta.dev/api/v1/get_alerts',
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "pair": value,
          "cross": cross,
          "price": price,
          "username": username
        })
      })
      .then(res => res.json())
      .then(data => {
        console.log(data['message']);
        setMessage(data['message']);
      }
    )
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  return (
    <div className="App">
      <h1>Crypto Alerts</h1>
      <br/>
      <h4>when</h4>
      <AutoComplete
        style={{
          width: 200,
        }}
        options={options}
        placeholder="Select a Crypto"
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onSelect={handleSelect}
        allowClear={true}
      />
      <h4>Cross</h4>
      <Select defaultValue="above/below" style={{ width: 120 }} onChange={handleCross}>
        <Option value="above">above</Option>
        <Option value="below">below</Option>
      </Select>
      <h4>Price</h4>
      <Input placeholder="Enter the price" style={{ width: 120 }} onChange={handlePrice}/>
      <br/>
      <br/>
      <h4>Enter your telegram username without "@" to get alerts</h4>
      <Input placeholder="Enter your telegram chat id" style={{ width: 120 }} onChange={handleUsername}/>

      <Button type="link" onClick={showModal}>instructions</Button>
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Go to telegram app and search "@crypt0_alerts_bot"</p>
        <p>Click "start" and you can now start receiving alerts</p>         
      </Modal>
      <br/>
      <br/>
      <Button onClick={handleSubmit}>Set alert</Button>
      <br/>
      <br/>
      {
        isloaded ?
        <Alert message={message} type="success" />
        :
        <div></div>
      }
    </div>
  );
}

export default App;
